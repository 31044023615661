import React, { useState, useEffect } from 'react'
import "./style.css"
import { Image } from 'antd'
import bastar2 from '../../Assets/BASTAR/IMG_20231029_104637556_BURST001_COMP.avif'
import bastar7 from '../../Assets/BASTAR/IMG_20231031_165001990.avif'
import kanker6 from '../../Assets/Kanker/IMG_20231015_165357635.avif'
import kanker11 from '../../Assets/Kanker/IMG_20231017_102332494_HDR.avif'
import mirzapur5 from '../../Assets/Mirzapur/IMG_20240124_110319.avif'
import mirzapur10 from '../../Assets/Mirzapur/IMG_20240124_160008.avif'
import ranchi3 from '../../Assets/Ranchi/IMG_20231204_160906899.avif'
import ranchi4 from '../../Assets/Ranchi/IMG_20240116_113851440.avif'
// new files 
import Livelihood1 from "../../Assets/LivelihoodUniversalization/IMG_20231010_124441341.jpg"
import Livelihood2 from "../../Assets/LivelihoodUniversalization/IMG_20231010_122056031_BURST000_COVER.jpg"

import Groupprocesses1 from "../../Assets/GroupProcessesandfacilitation/IMG_20231015_123100498.jpg"
import Groupprocesses2 from "../../Assets/GroupProcessesandfacilitation/IMG_20231015_123756408.jpg"
import Groupprocesses3 from "../../Assets/GroupProcessesandfacilitation/IMG_20231015_165357635.jpg"
import Groupprocesses4 from "../../Assets/GroupProcessesandfacilitation/IMG_20231016_154212696.jpg"
import Groupprocesses5 from "../../Assets/GroupProcessesandfacilitation/IMG_20231016_155400253~2.jpg"
import Groupprocesses6 from "../../Assets/GroupProcessesandfacilitation/IMG_20231017_163616649.jpg"

import TrainingState1 from "../../Assets/TrainingStateResource/IMG_20231130_123535295.jpg"
import TrainingState2 from "../../Assets/TrainingStateResource/IMG_20231204_160906899.jpg"
import TrainingState3 from "../../Assets/TrainingStateResource/IMG_20240116_113851440.jpg"
import TrainingState4 from "../../Assets/TrainingStateResource/IMG_20240120_123545180_BURST001_COMP.jpg"

import Previsioning1 from "../../Assets/Groupfacilitationandleadership/2W5A8833.jpg"
import Previsioning2 from "../../Assets/Groupfacilitationandleadership/2W5A8835.jpg"
import Previsioning3 from "../../Assets/Groupfacilitationandleadership/2W5A8838.jpg"
import Previsioning4 from "../../Assets/Groupfacilitationandleadership/2W5A8839.jpg"
import Previsioning5 from "../../Assets/Groupfacilitationandleadership/2W5A8841.jpg"
import Previsioning6 from "../../Assets/Groupfacilitationandleadership/2W5A8843.jpg"
import Previsioning7 from "../../Assets/Groupfacilitationandleadership/DSC01124.jpg"
import Previsioning8 from "../../Assets/Groupfacilitationandleadership/DSC01266.jpg"
import Previsioning9 from "../../Assets/Groupfacilitationandleadership/DSC01270.jpg"

const Media = () => {
  return (
    <div className='relative pt-[10%] lg:pt-[5%] pb-2'>
      <h2 className='FontCinzel text-center py-6 text-lg md:text-xl lg:text-3xl font-bold '>Annual Action Planning TOT of CLF, Mirzapur</h2>
      <div>
        <div className='hidden lg:flex justify-center py-6'>
          <div className="grid grid-cols-4 grid-rows-7 gap-4 w-[80%] h-[80vh]">
            <div className="row-span-2 overflow-hidden"><div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={bastar2} className="" /></div></div>
            <div className="col-span-2 row-span-2 overflow-hidden"><div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={ranchi3} className="scale-y-[2]" /></div></div>
            <div className="row-span-4 col-start-4 overflow-hidden"><div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={ranchi4} className="scale-x-[3]" /></div></div>
            <div className="row-span-2 col-start-3 row-start-3 overflow-hidden"><div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={mirzapur10} className="scale-[1.5]" /></div></div>
            <div className="col-span-2 row-span-3 col-start-1 row-start-3 overflow-hidden"><div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={kanker11} className="scale-y-[1.3]" /></div></div>
            <div className="row-span-2 col-start-1 row-start-6 overflow-hidden"><div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={bastar7} className="" /></div></div>
            <div className="row-span-2 col-start-2 row-start-6 overflow-hidden"><div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={kanker6} className="scale-[1.5]" /></div></div>
            <div className="col-span-2 row-span-3 col-start-3 row-start-5 overflow-hidden"><div className="duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={mirzapur5} className="scale-y-[2]" /></div></div>
          </div>
        </div>
        <div className='hidden md:flex lg:hidden justify-center py-6'>
          <div className="grid grid-cols-3 grid-rows-8 gap-4 w-[80%] h-[80vh]">
            <div className="col-span-2 row-span-2 overflow-hidden"><div className="duration-1000 hover:scale-110 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={bastar2} /></div></div>
            <div className="col-span-2 row-span-2 col-start-2 row-start-3 overflow-hidden"><div className="duration-1000 hover:scale-110 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={ranchi3} /></div></div>
            <div className="row-span-2 col-start-3 row-start-1 overflow-hidden"><div className="duration-1000 hover:scale-110 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={mirzapur10} /></div></div>
            <div className="row-span-2 col-start-1 row-start-3 overflow-hidden"><div className="duration-1000 hover:scale-110 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={ranchi4} /></div></div>
            <div className="row-span-2 col-start-1 row-start-7 overflow-hidden"><div className="duration-1000 hover:scale-110 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={kanker11} /></div></div>
            <div className="row-span-2 col-start-3 row-start-5 overflow-hidden"><div className="duration-1000 hover:scale-110 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={bastar7} /></div></div>
            <div className="col-span-2 row-span-2 col-start-2 row-start-7 overflow-hidden"><div className="duration-1000 hover:scale-110 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={kanker6} /></div></div>
            <div className="col-span-2 row-span-2 col-start-1 row-start-5 overflow-hidden"><div className="duration-1000 hover:scale-110 w-full h-full"><ShowAllImages checkGroup="annual" coverSrc={mirzapur5} /></div></div>
          </div>
        </div>
        <div className='md:hidden flex justify-center py-6'>
          <div className="grid grid-cols-2 grid-rows-13 gap-4  w-[80%] h-[80vh]">
            <div className="row-span-3 rounded-sm"><ShowAllImages checkGroup="annual" coverSrc={bastar2} /></div>
            <div className="row-span-3 col-start-2 row-start-2 rounded-sm"><ShowAllImages checkGroup="annual" coverSrc={ranchi3} /></div>
            <div className="row-span-3 row-start-4 rounded-sm"><ShowAllImages checkGroup="annual" coverSrc={mirzapur10} /></div>
            <div className="row-span-3 col-start-2 row-start-5 rounded-sm"><ShowAllImages checkGroup="annual" coverSrc={ranchi4} /></div>
            <div className="row-span-3 row-start-7 rounded-sm"><ShowAllImages checkGroup="annual" coverSrc={kanker11} /></div>
            <div className="row-span-3 col-start-2 row-start-8 rounded-sm"><ShowAllImages checkGroup="annual" coverSrc={bastar7} /></div>
            <div className="row-span-3 row-start-10 rounded-sm"><ShowAllImages checkGroup="annual" coverSrc={kanker6} /></div>
            <div className="row-span-3 col-start-2 row-start-11 rounded-sm"><ShowAllImages checkGroup="annual" coverSrc={mirzapur5} /></div>
          </div>
        </div>
      </div>

      <h2 className='FontCinzel text-center py-6 text-lg md:text-xl lg:text-3xl font-bold '>Livelihood universalization, Lucknow</h2>

      <div class="h-full py-6 sm:py-8">
        <div class="mx-auto max-w-screen-2xl w-[80%]">
          <div class="grid grid-cols-2 gap-4 sm:grid-cols-3">
            <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
              <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="livelihood" coverSrc={Livelihood1} className="scale-x-150" /></div>
            </div>
            <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg md:col-span-2 xl:h-80">
              <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="livelihood" coverSrc={Livelihood2} className="scale-y-[1.5]" /></div>
              <div class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 className='FontCinzel text-center py-6 text-lg md:text-xl lg:text-3xl font-bold '>Group processes and facilitation, Kanker</h2>

      <div className='flex justify-center'>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-5 w-[80%]">
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="group" coverSrc={Groupprocesses1} className="scale-x-[1.2]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="group" coverSrc={Groupprocesses2} className="scale-x-[1.4]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="group" coverSrc={Groupprocesses3} className="scale-x-[1.5]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="group" coverSrc={Groupprocesses4} className="scale-x-[1.3]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="group" coverSrc={Groupprocesses5} className="scale-x-[1.5]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="group" coverSrc={Groupprocesses6} className="scale-x-[1.7]" /></div>
          </div>
        </div>
      </div>

      <h2 className='FontCinzel text-center py-6 text-lg md:text-xl lg:text-3xl font-bold '>Training of State Resource Persons for SMIB and SD, Ranchi</h2>

      <div class="h-full py-6 sm:py-8">
        <div class="mx-auto max-w-screen-2xl w-[80%]">
          <div class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:gap-6 xl:gap-8">
            <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
              <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="training" coverSrc={TrainingState1} className="scale-x-[1.5]" /></div>
            </div>
            <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg md:col-span-2 xl:h-80">
              <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="training" coverSrc={TrainingState2} className="scale-y-[1.3]" /></div>
              <div
                class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
              </div>
            </div>
            <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg md:col-span-2 xl:h-80">
              <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="training" coverSrc={TrainingState3} className="scale-[1.2]" /></div>
              <div
                class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
              </div>
            </div>
            <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
              <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="training" coverSrc={TrainingState4} className="scale-x-[1.6] scale-y-[1.2]" /></div>
              <div
                class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <h2 className='FontCinzel text-center py-6 text-lg md:text-xl lg:text-3xl font-bold '>Group facilitation and leadership TOT, Bhopal</h2> */}
      <h2 className='FontCinzel text-center py-6 text-lg md:text-xl lg:text-3xl font-bold '>Pre-visioning Training, Lucknow</h2>

      <div className='flex justify-center'>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-5 w-[80%]">
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="previsioning" coverSrc={Previsioning1} className="scale-x-[1.3]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="previsioning" coverSrc={Previsioning2} className="scale-x-[1.3]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="previsioning" coverSrc={Previsioning3} className="scale-x-[1.3]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="previsioning" coverSrc={Previsioning4} className="scale-x-[1.3]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="previsioning" coverSrc={Previsioning5} className="scale-x-[1.4]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="previsioning" coverSrc={Previsioning6} className="scale-x-[1.4]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="previsioning" coverSrc={Previsioning7} className="scale-x-[1.5]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="previsioning" coverSrc={Previsioning8} className="scale-x-[1.5]" /></div>
          </div>
          <div class="group relative flex h-32 sm:h-36 md:h-48 items-end overflow-hidden shadow-lg xl:h-80">
            <div className=" duration-1000 hover:scale-105 w-full h-full"><ShowAllImages checkGroup="previsioning" coverSrc={Previsioning9} className="scale-x-[1.5]" /></div>
          </div>
        </div>
      </div>
    </div>
  )
}
const ShowAllImages = ({ coverSrc, className, checkGroup }) => {
  const [group, setGroup] = useState([])

  const AnnualActionPlanning = [coverSrc, bastar2, ranchi3, ranchi4, mirzapur10, kanker11, bastar7, kanker6, mirzapur5
  ]
  const Livelihood = [coverSrc, Livelihood1, Livelihood2]
  const Group = [coverSrc, Groupprocesses1, Groupprocesses2, Groupprocesses3, Groupprocesses4, Groupprocesses5, Groupprocesses6]
  const Training = [coverSrc, TrainingState1, TrainingState2, TrainingState3, TrainingState4]
  const Previsioning = [coverSrc, Previsioning1, Previsioning2, Previsioning3, Previsioning4, Previsioning5, Previsioning6, Previsioning7, Previsioning8, Previsioning9]

  useEffect(() => {
    switch (checkGroup) {
      case "annual":
        setGroup(AnnualActionPlanning)
        break;
      case "livelihood":
        setGroup(Livelihood)
        break;
      case "group":
        setGroup(Group)
        break;
      case "training":
        setGroup(Training)
        break;
      case "previsioning":
        setGroup(Previsioning)
        break;
      default:
        break;
    }
  }, [checkGroup])

  return (
    <Image.PreviewGroup
      items={group}
    >
      <Image
        width="100%"
        height="100%"
        preview={coverSrc}
        src={coverSrc}
        className={className}
      />
    </Image.PreviewGroup>
  )
}

// const ImageShow = ({ src }) => {
//   return (
//     <Zoom delay={500}>
//       {/* <Image src={src}  className='' loading='lazy' /> */}
//       <Image.PreviewGroup
//         items={[
//           'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp',
//           'https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp',
//           'https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp',
//         ]}
//       >
//         <Image
//           width="100%"
//           height="100%"
//           src={src}
//         />
//       </Image.PreviewGroup>
//     </Zoom>
//   )
// }


export default Media