import React from 'react'
import "./program.css"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import trainingImg from "../../Assets/program/training.avif"
import groupImg from "../../Assets/program/group.avif"
import orgImg from "../../Assets/program/oraganistion.avif"
import leadershipImg from "../../Assets/program/leadership.avif"
import collectiveImg from "../../Assets/program/collective.avif"
import evalutionImg from "../../Assets/program/evalution.avif"

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Fade, Slide } from 'react-swift-reveal'

const Programs = () => {
  return (
    <>
      <div className='relative programBanner lg:flex min-h-screen overflow-hidden before:absolute before:w-full before:h-full before:bg-black before:opacity-40'>
        <div className='relative lg:w-[50%]'>
          <div className='absolute top-[20%] sm:top-[55%] left-1/2 -translate-x-1/2'>
            <div className='relative'>
              <h2 className='font-extrabold text-6xl md:text-8xl 2xl:text-[10rem] font-mono text-white leading-none drop-shadow-2xl'>PROGRAMS</h2>
              {/* <h5 className='font-extrabold textstroke text-2xl md:text-3xl 2xl:text-4xl font-mono text-white tracking-tighter absolute -bottom-1 md:bottom-0 2xl:bottom-2 left-[5%]'>BY US WITH LOVE</h5> */}
            </div>
            {/* <p className='py-[3%] px-[6px] text-white'></p> */}
            <p className='font-rubik md:text-lg py-[3%] px-[6px] text-white'>Brainstem work with groups and collectives that have a vision for socio-economic change and take responsibility for its delivery along with teams that are into promotion of such groups and collectives.</p>
            {/* <div className='flex items-center gap-6 pt-[5%] pl-[2%] text-white'>
              <span className='font-mono text-sm'>Find Us Here</span>
              <span>-</span>
              <span><FacebookIcon fontSize='small' /></span>
              <span><InstagramIcon fontSize='small' /></span>
              <span><LinkedInIcon fontSize='small' /></span>
              <span><YouTubeIcon fontSize='small' /></span>
              <span><XIcon fontSize='small' /></span>
            </div> */}
          </div>
        </div>

        {/* Svg 1 left */}
        <svg viewBox="0 0 100 100" width={600} height={600} className='absolute hidden top-[-40%] opacity-50'>
          <defs>
            <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
              <stop id="stop1" stop-color="rgba(255, 255, 255, 1)" offset="0%"></stop>
              <stop id="stop2" stop-color="rgba(255, 255, 255, 1)" offset="100%"></stop>
            </linearGradient>
          </defs>
          <path fill="url(#sw-gradient)" d="M28.2,-29.7C34.2,-22.2,35,-11.1,32.8,-2.1C30.7,6.9,25.6,13.7,19.7,20.4C13.7,27,6.9,33.5,-2.3,35.8C-11.5,38.1,-22.9,36.2,-26.4,29.6C-30,22.9,-25.6,11.5,-24.6,1C-23.6,-9.4,-25.9,-18.9,-22.4,-26.3C-18.9,-33.8,-9.4,-39.2,0.8,-40.1C11.1,-40.9,22.2,-37.1,28.2,-29.7Z" width="100%" height="100%" transform="translate(50 50)" stroke-width="0" style={{ transition: "all 0.3s ease 0s" }} stroke="url(#sw-gradient)"></path>
        </svg>
        {/* Svg 2 left down */}
        <svg viewBox="0 0 100 100" width={800} height={800} className='absolute hidden -left-[35%] 2xl:-left-[25%] bottom-[-20%] opacity-50'>
          <defs>
            <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
              <stop id="stop1" stop-color="rgba(255, 255, 255, 1)" offset="0%"></stop>
              <stop id="stop2" stop-color="rgba(255, 255, 255, 1)" offset="100%"></stop>
            </linearGradient>
          </defs>
          <path fill="url(#sw-gradient)" d="M23.9,-30C26.8,-26,22.1,-14.6,21.9,-5.4C21.7,3.7,25.9,10.6,24,14C22.2,17.4,14.3,17.4,7.7,18.7C1.2,20,-4,22.5,-9.8,22.4C-15.7,22.3,-22.2,19.5,-26.6,14.2C-30.9,9,-33,1.4,-32.8,-6.9C-32.7,-15.2,-30.3,-24.2,-24.4,-27.7C-18.6,-31.2,-9.3,-29.1,0.6,-29.9C10.5,-30.6,21,-34.1,23.9,-30Z" width="100%" height="100%" transform="translate(50 50)" stroke-width="0" style={{ transition: "all 0.3s ease 0s" }} stroke="url(#sw-gradient)"></path>
        </svg>
        {/* Svg 3 Right  */}
        <svg viewBox="0 0 100 100" width={800} height={800} className='absolute hidden -right-[35%] lg:top-[-25%] 2xl:-right-[25%] opacity-50'>
          <defs>
            <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
              <stop id="stop1" stop-color="rgba(255, 255, 255, 1)" offset="0%"></stop>
              <stop id="stop2" stop-color="rgba(255, 255, 255, 1)" offset="100%"></stop>
            </linearGradient>
          </defs>
          <path fill="url(#sw-gradient)" d="M16.3,-22.2C20.3,-19.6,22,-13.6,23.1,-8C24.2,-2.4,24.7,2.9,23.3,7.7C21.9,12.4,18.5,16.6,14.3,22.4C10.1,28.2,5,35.6,-0.8,36.6C-6.6,37.7,-13.2,32.5,-19,27.2C-24.7,21.9,-29.6,16.6,-33.5,9.7C-37.4,2.8,-40.3,-5.6,-38.2,-12.4C-36,-19.2,-28.7,-24.3,-21.5,-25.8C-14.2,-27.4,-7.1,-25.4,-0.5,-24.7C6.2,-24.1,12.4,-24.8,16.3,-22.2Z" width="100%" height="100%" transform="translate(50 50)" stroke-width="0" style={{ transition: "all 0.3s ease 0s" }} stroke="url(#sw-gradient)"></path>
        </svg>
        <div class="custom-shape-divider-bottom-1716876360">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
          </svg>
        </div>
      </div>
      <div className='overflow-hidden'>
        {/* <div className='flex justify-center'><div className='w-full h-[80vh] bg-[url("https://images.pexels.com/photos/132037/pexels-photo-132037.jpeg")] bg-center bg-cover'></div></div> */}
        {/* section 1  */}
        <div className='flex justify-center mt-[5%]'>
          <div className='lg:flex lg:w-[80%] p-4'>
            <div className='lg:w-1/2'>
              <Fade delay={500}>
                <h2 className='text-2xl md:text-4xl 2xl:text-5xl py-4 text-primary1'>TRAINING DESIGN AND FACILITATION (TDF)</h2>
                {/* <h4 className='text-xl md:text-2xl 2xl:text-3xl py-4'>edfgh defgh dtfygjhuki erfgthyju</h4> */}
                <p className='text-sm md:text-base 2xl:text-xl lg:w-[75%]'>TDF is designed not just to impart knowledge and conceptual understanding of the Subject in hand but also aims to equip the participants in participatory training design methodology and facilitation that can be for capacity enhancement of target group and clients.</p>
                <div className='pt-4 space-y-3'>
                  <h2 className='underline'>Content includes: </h2>
                  <ul className='pl-8 space-y-2'>
                    <li className='list-disc'>Training Needs Assessment and Tools</li>
                    <li className='list-disc'>Training Design Principles</li>
                    <li className='list-disc'>Adult Learning Principles</li>
                    <li className='list-disc'>Delivery process Design</li>
                    <li className='list-disc'>Maximize participation</li>
                    <li className='list-disc'>Application of Games and Tools</li>
                  </ul>
                </div>
              </Fade>
            </div>
            <div className='lg:w-1/2 flex items-center'>
              <div className=' drop-shadow-2xl'><Slide right><LazyLoadImage src={trainingImg} alt='' width={500} /></Slide></div>
            </div>
          </div>
        </div>
        {/* divider */}
        <div className="h-[1px] w-full my-9 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"></div>
        {/* section 2  */}
        <div className='flex justify-center mt-[5%]'>
          <div className='lg:flex lg:flex-row-reverse lg:w-[80%] p-4'>
            <div className='lg:w-1/2 lg:pl-[5%]'>
              <Fade delay={500}>
                <h2 className='text-2xl md:text-4xl 2xl:text-5xl py-4 text-primary1'>UNDERSTAND AND WORK WITH GROUPS</h2>
                {/* <h4 className='text-xl md:text-2xl 2xl:text-3xl py-4'>edfgh defgh dtfygjhuki erfgthyju</h4> */}
                <p className='text-sm md:text-base 2xl:text-xl lg:w-[75%]'>Groups are necessary to function in today’s world where emphasis is on working together and form networks and associations. Groups and Collectives are as important to development as associations and networks are to business.</p><br />
                <p className='text-sm md:text-base 2xl:text-xl lg:w-[75%]'>BRAINSTEM’s program helps to understand what are groups and collectives, principles and mechanisms of groups, decision making process, consensus and other aspects of group behaviour like Group Health Inventory.</p>
              </Fade>
            </div>
            <div className='lg:w-1/2 flex items-center'>
              <div className=' drop-shadow-2xl'><Slide left><LazyLoadImage src={groupImg} alt='' width={500} /></Slide></div>
            </div>
          </div>
        </div>
        {/* divider */}
        <div className="h-[1px] w-full my-9 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"></div>
        {/* section 3  */}
        <div className='flex justify-center mt-[5%]'>
          <div className='lg:flex lg:w-[80%] p-4'>
            <div className='lg:w-1/2'>
              <Fade delay={500}>
                <h2 className='text-2xl md:text-4xl 2xl:text-5xl py-4 text-primary1'>SYSTEM SETTING -  ORGANIZATION</h2>
                {/* <h4 className='text-xl md:text-2xl 2xl:text-3xl py-4'>edfgh defgh dtfygjhuki erfgthyju</h4> */}
                <p className='text-sm md:text-base 2xl:text-xl lg:w-[75%]'>Development and Start up organizations face multiple challenges in coping with organizational processes, business promotion and external linkages. A successful organization must successfully deal on all fronts effectively and it needs professional ideas, action and systems to put all of it together and function.</p><br />
                <p className='text-sm md:text-base 2xl:text-xl lg:w-[75%]'>BRAINSTEM provides consultancy support for MIS, financial, operation, human resource and business system setting.</p>
              </Fade>
            </div>
            <div className='lg:w-1/2 flex items-center'>
              <div className=' drop-shadow-2xl'><Slide right><LazyLoadImage src={orgImg} alt='' width={500} /></Slide></div>
            </div>
          </div>
        </div>
        {/* divider */}
        <div className="h-[1px] w-full my-9 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"></div>
        {/* section 4  */}
        <div className='flex justify-center mt-[5%]'>
          <div className='lg:flex lg:flex-row-reverse lg:w-[80%] p-4'>
            <div className='lg:w-1/2 lg:pl-[5%]'>
              <Fade delay={500}>
                <h2 className='text-2xl md:text-4xl 2xl:text-5xl py-4 text-primary1'>PRODUCER GROUPS AND COLLECTIVES -  SYSTEMS</h2>
                {/* <h4 className='text-xl md:text-2xl 2xl:text-3xl py-4'>edfgh defgh dtfygjhuki erfgthyju</h4> */}
                <p className='text-sm md:text-base 2xl:text-xl lg:w-[75%]'>Member and Producer Organizations like SHG Federations, Cooperatives and Producer Companies have been very popular as Development strategy. Governance and Management functions of such collective agencies are often very flexible and involving but immensely critical to their success</p><br />
                <p className='text-sm md:text-base 2xl:text-xl lg:w-[75%]'>BRAINSTEM runs modules for members for such collectives and also for promoters of such collectives in managing leadership and operational challenges.</p>
              </Fade>
            </div>
            <div className='lg:w-1/2 flex items-center'>
              <div className=' drop-shadow-2xl'><Slide left><LazyLoadImage src={collectiveImg} alt='' width={450} /></Slide></div>
            </div>
          </div>
        </div>
        {/* divider */}
        <div className="h-[1px] w-full my-9 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"></div>
        {/* section 5  */}
        <div className='flex justify-center mt-[5%]'>
          <div className='lg:flex lg:w-[80%] p-4'>
            <div className='lg:w-1/2'>
              <Fade delay={500}>
                <h2 className='text-2xl md:text-4xl 2xl:text-5xl py-4 text-primary1'>LEADERSHIP STYLES</h2>
                {/* <h4 className='text-xl md:text-2xl 2xl:text-3xl py-4'>edfgh defgh dtfygjhuki erfgthyju</h4> */}
                <p className='text-sm md:text-base 2xl:text-xl lg:w-[75%]'>Leadership is critical for future growth of any organization and leadership styles are often very differentiated.</p><br />
                <p className='text-sm md:text-base 2xl:text-xl lg:w-[75%]'>BRAINSTEM offers modules on leadership motivation like expert influence, achievement motivation which unpack the leadership motivations of different people and outline the appropriate conditions for emergence of leadership potential of staff.</p>
              </Fade>
            </div>
            <div className='lg:w-1/2 flex items-center'>
              <div className=' drop-shadow-2xl'><Slide right><LazyLoadImage src={leadershipImg} alt='' width={500} /></Slide></div>
            </div>
          </div>
        </div>
        {/* divider */}
        <div className="h-[1px] w-full my-9 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"></div>
        {/* section 6  */}
        <div className='flex justify-center my-[5%]'>
          <div className='lg:flex lg:flex-row-reverse lg:w-[80%] p-4'>
            <div className='lg:w-1/2 lg:pl-[5%]'>
              <Fade delay={500}>
                <h2 className='text-2xl md:text-4xl 2xl:text-5xl py-4 text-primary1'>PROCESS AND PROGRAMME EVALUATIONS</h2>
                {/* <h4 className='text-xl md:text-2xl 2xl:text-3xl py-4'>edfgh defgh dtfygjhuki erfgthyju</h4> */}
                <p className='text-sm md:text-base 2xl:text-xl lg:w-[75%]'>BRAINSTEM runs evaluation processes to help capture the effectiveness of Processes and programmes run by organizations to meet their objectives. Such an exercise outlines the strength areas, possible gaps and threats along with opportunities for the organization future growth and its programmes.</p>
              </Fade>
            </div>
            <div className='lg:w-1/2 flex items-center'>
              <div className=' drop-shadow-2xl'><Slide left><LazyLoadImage src={evalutionImg} alt='' width={500} /></Slide></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Programs